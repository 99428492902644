@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");

.single-admin {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin: 0px;
  color: #2c2c2c;
  line-height: normal;
  padding: 0px;
  background-color: white;
  min-height: 100vh;
}

/*body {
  background-color: #f8f8f8;
}*/
.form-md {
  width: 100%;
  margin-top: 25px;
}
.form-md .card {
  border: 0;
}
.single-admin .card-header {
  background-color: rgb(44 44 44);
  font-size: 17px;
  color: white;
  border-radius: 3px !important;
  padding: 12px 21px;
}
.single-admin .card-header:first-child {
  border-radius: 5px 5px 0 0;
}

:root {
  --hue: 223;
  --bg: hsl(var(--hue), 90%, 90%);
  --fg: hsl(var(--hue), 90%, 10%);
  --primary: #6e4c43;
  --trans-dur: 0.3s;
  --trans-timing: cubic-bezier(0.65, 0, 0.35, 1);
  font-size: calc(16px + (24 - 16) * (100vw - 320px) / (2560 - 320));
}
.single-admin header {
  background-color: #2c2c2c;
  padding: 15px 10px;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0/8%);
}
.single-admin .uk-timeline-item.active.complete {
  padding-bottom: 30px;
}

.single-admin header img {
  width: 150px;
  filter: contrast(0) brightness(10000000);
}
.single-admin .import-form {
  display: none;
}
.single-admin header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.single-admin header .profile {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;

  text-align: end;
  padding-right: 100px;
  min-height: 39px;
}
.single-admin header .profile h3 {
  text-align: end;
  line-height: 1;
  margin-bottom: 0px;
  font-weight: 400;
  color: whitesmoke;
  font-size: 15px;
}
.single-admin header .profile p {
  line-height: 1;
  font-size: 13px;
  opacity: 0.6;
  margin: 0;
  color: white;
}
.single-admin header .profile img {
  position: absolute;
  right: 50px;
  width: 35px;
  top: 2px;
  cursor: pointer;
}

.single-admin header .profile img.logout {
  right: 0;
  border: 2px solid white;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 5px;
}

/* menu (the right one) */
.single-admin .menu {
  position: absolute;
  top: calc(100% + 44px);
  right: 16px;
  width: 180px;
  min-height: 100px;
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translateY(-10px);
  visibility: hidden;
  transition: 300ms;
  z-index: 1111;
}
.single-admin .menu::before {
  content: "";
  position: absolute;
  top: -10px;
  right: 14px;
  width: 20px;
  height: 20px;
  background: #fff;
  transform: rotate(45deg);
  z-index: -1;
}
.single-admin .menu.active {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

/* menu links */
.single-admin .menu ul {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
  background: #fff;
  padding-left: 0;
  margin-bottom: 0;
}
.single-admin .menu ul li {
  list-style: none;
}
.single-admin .menu ul li:hover {
  background: #eee;
}
.single-admin .menu ul li a {
  text-decoration: none;
  color: #000;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  gap: 6px;
  font-size: 16px;
}
.single-admin .menu ul li a i {
  font-size: 1.2em;
}
.single-admin .drop_box {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #dddddd;
  border-radius: 5px;
  position: relative;
  /* width: 25%;
  float: left; */
  margin-top: 10px;
}
.single-admin .drop_box p {
  margin: 9px auto;
  font-size: 12px;
  color: #a8a8a8;
  text-align: center;
}
.single-admin .drop_box .file-icon {
  width: 32px;
}
.single-admin .drop_box input {
  /* margin: 10px 0;
  width: 100%;
  background-color: #e2e2e2;
  border: none;
  outline: none;
  padding: 12px 20px;
  border-radius: 4px; */
  /* display: none; */
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.form-md .gl-form-asterisk {
  background-color: inherit;
  color: #e32b2b;
  padding: 0;
  padding-left: 3px;
}
.form-md .gl-form-asterisk:after {
  content: "*";
}
.form-label {
  font-weight: 500;
}
.single-admin input.form-control,
.single-admin .form-select {
  font-size: 14px;
  height: 45px;
  border-radius: 3px;
}
.single-admin input.form-control::placeholder {
  color: #989898;
}
.single-admin input.form-control::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #989898;
}
.single-admin input.form-control:focus,
.single-admin .form-select:focus {
  border: 1px solid #2c2c2c;
  outline: none;
  box-shadow: none;
}

.form-md .btn-primary {
  background-color: #2c2c2c;
  border-color: #2c2c2c;
  border-radius: 3px;
  font-size: 14px;
  margin-top: 27px;
  width: 100%;
}
.form-md .reset-btn,
.form-md .btn-primary:hover,
.form-md .btn-primary.active,
.form-md .btn-primary:focus {
  background-color: #6e4c43;
  border-color: #6e4c43;
  color: #fff;
  box-shadow: none;
}

.form-md .drop_box .btn-primary {
  background-color: #6e4c43;
  border-color: #6e4c43;
  color: #fff;
  box-shadow: none;
  width: max-content;
  margin-top: 3px;
  font-size: 14px;
  position: relative;
}

.form-md .drop_box .btn-primary img {
  width: 30px;
  margin-right: 3px;
}

.form-md .submit-job:hover,
.form-md .submit-job:focus,
.form-md .drop_box .btn-primary:hover,
.form-md .drop_box .btn-primary:focus {
  background-color: #2c2c2c;
  border-color: #2c2c2c;
}
.single-admin .or {
  display: block;
  height: 0px;
  width: 49%;
  position: relative;
  margin: 0px auto;
  border-top: 1px dotted #717171;
  margin-top: 40px;
  margin-bottom: 20px;
}
.single-admin .or::before {
  content: "OR";
  left: 0;
  right: 0;
  height: 30px;
  background-color: #6e4c43;
  color: white;
  position: absolute;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  top: -16px;
  border-radius: 50%;
  outline: 10px solid white;
  font-size: 13px;
  font-weight: 700;
}
/* .single-admin .email-sent,
.single-admin .card-selection,
.single-admin .card-selection-new {
  display: none;
} */
.single-admin .question-heading {
  float: left;
  width: 70%;
  border-radius: 5px;
  background-color: rgb(44 44 44);
  font-size: 17px;
  color: white;
  padding: 12px 21px;
}
.question-heading-last-cards {
  width: 68%;
}
.yes-no {
  float: right;
}
.form-md .submit-job {
  margin-top: 0;
  padding: 0.5rem 1rem;
  background-color: #6e4c43;
  border-color: #6e4c43;
  color: #fff;
  box-shadow: none;
  width: 200px;
  margin-top: 0;
  margin-left: auto;
}
.yes-no .btn {
  margin-top: 0;

  position: relative;
  cursor: pointer;
  padding: 11px 20px;
}

.yes-no .btn input {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.single-admin .table thead.table-dark tr th {
  padding: 15px 18px;
  font-weight: 300;
  font-size: 13px;
}
.single-admin .card-selection img.img-fluid {
  /* padding: 15px;
  background: white; */
}

/*css for new design */
.single-admin h4.inner-heading {
  font-size: 16px;
}
.single-admin .progress-file {
  width: 100%;
  float: none;
}
.single-admin .progress-file .progress {
  --bs-progress-height: 10px;
  width: 100%;
  --bs-progress-height: 10px;
  width: 100%;
  --bs-progress-bg: transparent;
  border: 1px solid #cccccc;
}
.single-admin .progress-file .progress .progress-bar {
  background-color: #6e4c43;
  border-radius: 0.375rem;
}
.single-admin .progress-file h6 {
  font-size: 15px;
  margin-top: 15px;
  margin-bottom: 0;
}
.single-admin .progress-file p {
  font-size: 12px;
  margin-bottom: 8px;
  margin-top: 10px;
}
.single-admin .progress-file p span {
  color: #a1a1a1;
  margin-left: 20px;
}
.single-admin .progress-file p button.btn.btn-link {
  color: #a1a1a1;
  padding: 0px;
  float: right;
  margin-top: -12px;
}
.single-admin .progress-file .done-perc {
  font-size: 12px;
  color: #a1a1a1;
  margin-top: 4px;
}
.single-admin .progress-file .done-perc span {
  width: fit-content;
  display: inline-block;
  margin-left: auto;
  float: right;
}
.form-md .reset-btn,
.form-md .form-btn {
  height: 45px;
}
.single-admin .with-manage .btn-primary {
  width: fit-content;
  padding: 10.5px 43px;
}
.single-admin .with-manage .question-heading {
  width: 58%;
}
.single-admin .with-manage .yes-no {
  width: 42%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 18px;
}
.single-admin .alert-dismissible {
  padding-right: 1.5rem !important ;
}

.single-admin .card-body {
  padding: 1rem 0px;
}
.single-admin .table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-bg-type: rgb(0 149 242 / 5%);
}
.single-admin .table-striped button.btn .edit {
  width: 20px;
}
.single-admin .table-striped button.btn .deleted {
  width: 16px;
}
.single-admin .table-striped .form-check {
  min-height: auto;
}

.single-admin .table-striped .form-check-input[type="checkbox"] {
  float: none;
  width: 16px;
  height: 16px;
  margin-top: 1px;
  border: 1px solid #a4a4a4;
}
.single-admin .table-striped > tbody > tr > td {
  padding: 20px;
  border-bottom-width: 0;
}
.single-admin table.table.table-striped.align-middle {
  box-shadow: 0px 0px 19px -11px #0000006e;
}
.single-admin .action-job .btn {
  color: black !important;
  font-size: 15px;
  border-radius: 3px;
  padding: 6px 6px;
  margin-left: 10px;
  background-color: transparent !important;
}
.single-admin .action-job .btn-outline-success {
  border-color: #26a828 !important;
}
.single-admin .action-job .btn-outline-danger {
  border-color: #f84778 !important;
}
.single-admin .action-job .btn-outline-info {
  border-color: #67b9d6 !important;
}

.single-admin .action-job .btn-outline-warning.btn:disabled {
  background-color: transparent !important;
  color: black !important;
}

.single-admin .action-job .btn-outline-warning {
  border-color: #6e4c43 !important;
}

.single-admin .action-job .btn-outline-warning {
  background-color: #6e4c43 !important;
  color: white !important;
}

.single-admin .action-job .btn-outline-warning.btn:disabled img {
  filter: none !important;
}
.single-admin .action-job .btn-outline-warning img {
  filter: contrast(0) brightness(10000000);
}

.single-admin .action-job .btn-outline-success img {
  width: 25px;
  vertical-align: top;
  margin-top: 1px;
}
.single-admin .action-job .btn-outline-danger img {
  width: 16px;
  vertical-align: top;
  margin-top: 0px;
}
.single-admin .action-job .btn-outline-info img {
  width: 22px;
  vertical-align: top;
  margin-top: 1px;
}
.single-admin .action-job .btn-outline-warning img {
  width: 17px;
  vertical-align: top;
  margin-top: 2px;
}
.single-admin .cards-img {
  padding: 16px 50px;
  background: #f2fafe;
}
.single-admin .uk-timeline .uk-timeline-item {
  position: relative;
  display: none;
}
.single-admin #job-list.active,
.single-admin .uk-timeline .uk-timeline-item.active,
.single-admin .uk-timeline .uk-timeline-item.complete {
  display: flex;
}
.single-admin .uk-timeline .active.uk-timeline-item::before {
  height: 0;
}
.single-admin .uk-timeline .complete.uk-timeline-item::before {
  height: calc(100% - 35px);
  transition: all 1s;
}
.single-admin .uk-timeline .uk-timeline-item::before {
  background: #d1d1d1;
  content: "";
  left: 14px;
  position: absolute;
  top: 35px;
  width: 7px;
  z-index: 1;
}
.single-admin .uk-timeline .uk-timeline-item .uk-timeline-icon .uk-badge {
  box-sizing: border-box;
  min-width: 22px;
  height: 22px;
  padding: 0 5px;
  border-radius: 500px;
  vertical-align: middle;
  background: #1e87f0;
  color: #fff;
  font-size: 0.875rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.single-admin .uk-timeline .uk-timeline-item .uk-timeline-icon .uk-badge {
  margin-top: 0;
  width: 35px;
  height: 35px;
  position: relative;
  z-index: 111;
  background: #2c2c2c;
  font-size: 15px;
}
.single-admin .uk-timeline .uk-timeline-item .uk-timeline-content {
  padding: 0 0 0 0.6rem;
  width: 100%;
}
#customer-list,
#job-list,
#time-4 {
  display: none;
}
#time-4.active {
  display: block;
}
.single-admin .custom-popup .form-md {
  margin: 0;
}
.single-admin .custom-popup .form-md .card-body {
  padding: 0;
}
.single-admin .custom-popup .modal-header {
  padding-bottom: 0;
  border: none;
}
.single-admin .btn-close:hover {
  opacity: inherit;
}
.single-admin .custom-popup-2 .form-md {
  margin: 0;
}
.single-admin .custom-popup-2 .card-body {
  padding: 0;
  border: none;
}
.single-admin .custom-popup-2 .card {
  border: none;
}
.single-admin .custom-popup-2 .modal-header {
  padding-bottom: 0;
  border: none;
}
.single-admin .btn-close:hover {
  opacity: inherit;
}
.single-admin .custom-popup-2 .btn-primary {
  background-color: #2c2c2c;
  border-color: #2c2c2c;
  border-radius: 3px;
  font-size: 14px;
  margin-top: 27px;
  width: 100%;
}
.single-admin .custom-popup-2 .reset-btn,
.single-admin .custom-popup-2 .btn-primary:hover,
.single-admin .custom-popup-2 .btn-primary.active,
.single-admin .custom-popup-2 .btn-primary:focus {
  background-color: #6e4c43;
  border-color: #6e4c43;
  color: #fff;
  box-shadow: none;
}
.single-admin a.sample-sheet-btn {
  display: block;
  width: max-content;
  margin-left: auto;
  color: black;
  font-size: 15px;
  text-decoration: none;
}
.single-admin a.sample-sheet-btn img {
  margin-right: 15px;
}

.single-admin .page-link {
  font-size: 13px;
  padding: 7px 11px;
}

.ques-ico {
  width: 2.2em;
  height: 2.2em;
  margin: 0.7em auto 0.4em;
  border-width: 3px;
}
.ques-ico .swal2-icon-content {
  font-size: 34px;
}
.del-pop {
  width: 15em !important;
  padding: 0px 0px 0.75em !important;
}
.del-pop .swal2-title {
  padding: 0em 0.5em 0;

  font-size: 22px;
}
.del-pop .swal2-html-container {
  font-size: 17px;
  margin: 0.2em 0.5em 0em;
}
.del-pop .swal2-actions {
  margin: 0.5em auto 0;
}
.del-pop .swal2-actions button {
  border-radius: 3px;
  font-size: 14px;
  margin: 2px;
  padding: 5px 13px;
}

.single-admin .table-striped > tbody > tr.active > * {
  --bs-table-bg-type: #6e4c432b;
}
.single-admin .accordion-header .card-header {
  position: relative;
  font-weight: 400;
}

.single-admin .accordion-header .card-header.collapsed:after {
  transform: rotate(315deg);
  top: 12px;
  transition: all 0.3s;
}

.single-admin .accordion-header .card-header:after {
  content: "";
  width: 12px;
  height: 12px;
  border-left: 2px solid white;
  border-radius: 0 !important;
  border-bottom: 2px solid white;
  display: block;
  position: absolute;
  top: 19px;
  right: 20px;
  transform: rotate(135deg);
  transition: all 0.3s;
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .single-admin .question-heading {
    padding: 13px 11px;
    font-size: 16px;
  }
  .single-admin .with-manage .yes-no .btn-primary {
    padding: 10.5px 35px;
    width: fit-content;
  }
  .single-admin .with-manage .question-heading {
    padding: 12px 21px;
  }

  .yes-no .btn.active {
    padding: 11px 15px;
    width: 100%;
  }

  .single-admin .uk-timeline .uk-timeline-item .uk-timeline-content {
    width: calc(100% - 35px);
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .single-admin .uk-timeline .uk-timeline-item .uk-timeline-content {
    width: calc(100% - 35px);
  }
  .single-admin .question-heading {
    font-size: 12px;
    padding: 13.5px 10px;
  }
  .single-admin .with-manage .yes-no .btn-primary {
    padding: 10px 23px;
    font-size: 14px;
  }
  .single-admin .with-manage .question-heading {
    font-size: 16px;
    padding: 12px 21px;
  }

  .single-admin .with-manage .yes-no {
    padding-left: 10px;
  }
  .yes-no .btn {
    padding: 11px 17px;
    font-size: 11px;
  }
  .yes-no .btn.active {
    padding: 11px 14px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .single-admin .uk-timeline .uk-timeline-item .uk-timeline-content {
    width: calc(100% - 35px);
  }
  .single-admin .with-manage .question-heading {
    width: 100%;
  }
  .single-admin .with-manage .btn-primary {
    width: 32%;
  }
  .single-admin .with-manage .yes-no {
    width: 100%;
    padding-left: 0;
    margin-top: 13px;
  }
  .single-admin .cards-img {
    text-align: center;
    margin-top: 10px;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .single-admin .uk-timeline .uk-timeline-item .uk-timeline-content {
    width: calc(100% - 35px);
  }
  .single-admin .with-manage .question-heading,
  .single-admin .question-heading {
    width: 100%;
  }
  .yes-no {
    margin-top: 10px;
  }
  .single-admin .with-manage .btn-primary {
    width: 100%;
    margin-top: 5px;
  }
  .single-admin .with-manage .yes-no {
    width: 100%;
    padding-left: 0;
    margin-top: 13px;
    display: block;
  }

  .single-admin .cards-img {
    text-align: center;
    margin-top: 10px;
  }
  .form-md .btn-primary {
    margin-top: 0;
  }
  .single-admin h4.inner-heading {
    margin-bottom: 10px !important;
  }
  .single-admin header img {
    width: 150px;
  }
  .single-admin header .profile h3 {
    font-size: 16px;
  }
  .single-admin header .profile p {
    font-size: 12px;
  }
}
